@font-face {
  font-family: FrutigerNeue LT Pro;
  src: url("FrutigerNeueLTPro-Bold.e8edc8f2.eot");
  src: url("FrutigerNeueLTPro-Bold.e8edc8f2.eot#iefix") format("embedded-opentype"), url("FrutigerNeueLTPro-Bold.ea8c0e6e.woff2") format("woff2"), url("FrutigerNeueLTPro-Bold.5f7b64be.woff") format("woff"), url("FrutigerNeueLTPro-Bold.5f7eb32a.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: FrutigerNeue LT Pro;
  src: url("FrutigerNeueLTPro-Book.c31ae961.eot");
  src: url("FrutigerNeueLTPro-Book.c31ae961.eot#iefix") format("embedded-opentype"), url("FrutigerNeueLTPro-Book.a6f2b487.woff2") format("woff2"), url("FrutigerNeueLTPro-Book.67d7d985.woff") format("woff"), url("FrutigerNeueLTPro-Book.3f3d957d.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: FrutigerNeue LT Pro;
  src: url("FrutigerNeueLTPro-Black.fa9fcf00.eot");
  src: url("FrutigerNeueLTPro-Black.fa9fcf00.eot#iefix") format("embedded-opentype"), url("FrutigerNeueLTPro-Black.ffc299fc.woff2") format("woff2"), url("FrutigerNeueLTPro-Black.63cffe69.woff") format("woff"), url("FrutigerNeueLTPro-Black.9928d165.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
/*# sourceMappingURL=index.f423bfd4.css.map */
