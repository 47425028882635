@font-face {
  font-family: "FrutigerNeue LT Pro";
  src: url("/fonts/FrutigerNeueLTPro-Bold.eot");
  src: url("/fonts/FrutigerNeueLTPro-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/FrutigerNeueLTPro-Bold.woff2") format("woff2"),
    url("/fonts/FrutigerNeueLTPro-Bold.woff") format("woff"),
    url("/fonts/FrutigerNeueLTPro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "FrutigerNeue LT Pro";
  src: url("/fonts/FrutigerNeueLTPro-Book.eot");
  src: url("/fonts/FrutigerNeueLTPro-Book.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/FrutigerNeueLTPro-Book.woff2") format("woff2"),
    url("/fonts/FrutigerNeueLTPro-Book.woff") format("woff"),
    url("/fonts/FrutigerNeueLTPro-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "FrutigerNeue LT Pro";
  src: url("/fonts/FrutigerNeueLTPro-Black.eot");
  src: url("/fonts/FrutigerNeueLTPro-Black.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/FrutigerNeueLTPro-Black.woff2") format("woff2"),
    url("/fonts/FrutigerNeueLTPro-Black.woff") format("woff"),
    url("/fonts/FrutigerNeueLTPro-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
